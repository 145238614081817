var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"mb-7"},[_c('h1',[_vm._v("Devices")]),(_vm.errorMessage)?_c('Alert',{attrs:{"icon":"exclamation","variant":"red"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e(),_c('div',{staticClass:"col-span-12 mt-4"},[_c('div',{staticClass:"flex flex-wrap xl:flex-nowrap justify-start items-start search-wrap"},[_c('div',{staticClass:"relative max-w-md mb-2 pr-2 search w-2/3"},[_c('div',{staticClass:"pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"},[_c('font-awesome-icon',{staticClass:"h-5 w-5 text-gray-400 text-xl",attrs:{"icon":['far', 'magnifying-glass']}})],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"block w-100 rounded-md border h-11 pl-10",attrs:{"type":"search","placeholder":"Enter search"},domProps:{"value":(_vm.search)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.search=$event.target.value},function($event){return _vm.debounceInput()}]}})]),(_vm.isRbAdmin)?_c('BaseButton',{directives:[{name:"b-modal",rawName:"v-b-modal",value:('add-device-modal'),expression:"'add-device-modal'"}],staticClass:"h-11 mr-4",attrs:{"variant":"secondary-outline"}},[_vm._v(" Add Device ")]):_vm._e(),_c('BaseButton',{staticClass:"h-11 mr-4",attrs:{"variant":"secondary-outline"},nativeOn:{"click":function($event){return _vm.syncDevices($event)}}},[_vm._v(" Sync Devices ")]),_c('AddDeviceModal')],1)]),(!_vm.errorMessage)?_c('GenericTable',{attrs:{"columns":_vm.columns,"data":_vm.data,"pagination":_vm.pagination,"loading":_vm.loading,"actions":true},on:{"sort":_vm.sort,"updateCurrentPage":_vm.updateCurrentPage},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var row = ref.row;
return [_c('router-link',{staticClass:"p-2 text-gray-800 underline",attrs:{"to":{ path: ("device/" + (row.id)) }}},[_vm._v(" "+_vm._s(row.name)+" ")])]}},{key:"apkVersion",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.info.apkVersion)+" ")]}},{key:"environment",fn:function(ref){
var row = ref.row;
return [_c('a',{attrs:{"href":row.info.environmentUrl}},[_vm._v(" "+_vm._s(row.info.environmentUrl)+" ")])]}},{key:"webVersion",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.info.webAppCommitHash)+" ")]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('StatusBadge',{attrs:{"status":row.active}})]}},{key:"actions",fn:function(ref){
var row = ref.row;
return [(row.id)?_c('EditDeviceModal',{staticClass:"ml-4",attrs:{"deviceId":row.id},on:{"deviceUpdated":function($event){return _vm.deviceUpdated()}}}):_vm._e(),_c('span',{staticClass:"sr-only"},[_vm._v("Edit Device")])]}}],null,false,485101160)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
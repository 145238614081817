<template>
  <b-container class="mb-7">
    <h1>Devices</h1>
    <Alert icon="exclamation" variant="red" v-if="errorMessage">
      {{ errorMessage }}
    </Alert>
    <div class="col-span-12 mt-4">
      <div class="flex flex-wrap xl:flex-nowrap justify-start items-start search-wrap">
        <div class="relative max-w-md mb-2 pr-2 search w-2/3">
          <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <font-awesome-icon class="h-5 w-5 text-gray-400 text-xl" :icon="['far', 'magnifying-glass']" />
          </div>
          <input
            v-model="search"
            class="block w-100 rounded-md border h-11 pl-10"
            type="search"
            @input="debounceInput()"
            placeholder="Enter search"
          />
        </div>
        <BaseButton variant="secondary-outline" v-b-modal="'add-device-modal'" class="h-11 mr-4" v-if="isRbAdmin">
          Add Device
        </BaseButton>
        <BaseButton variant="secondary-outline" @click.native="syncDevices" class="h-11 mr-4">
          Sync Devices
        </BaseButton>
        <AddDeviceModal />
      </div>
    </div>

    <GenericTable
      v-if="!errorMessage"
      :columns="columns"
      :data="data"
      :pagination="pagination"
      :loading="loading"
      :actions="true"
      @sort="sort"
      @updateCurrentPage="updateCurrentPage"
    >
      <template #name="{ row }">
        <router-link :to="{ path: `device/${row.id}` }" class="p-2 text-gray-800 underline">
          {{ row.name }}
        </router-link>
      </template>
      <template #apkVersion="{ row }">
        {{ row.info.apkVersion }}
      </template>
      <template #environment="{ row }">
        <a :href="row.info.environmentUrl">
          {{ row.info.environmentUrl }}
        </a>
      </template>
      <template #webVersion="{ row }">
        {{ row.info.webAppCommitHash }}
      </template>
      <template #status="{ row }">
        <StatusBadge :status="row.active" />
      </template>
      <template #actions="{ row }">
        <EditDeviceModal @deviceUpdated="deviceUpdated()" v-if="row.id" :deviceId="row.id" class="ml-4" />
        <span class="sr-only">Edit Device</span>
      </template>
    </GenericTable>
  </b-container>
</template>

<script>
import { getAuth, Roles } from '@rafflebox-technologies-inc/auth-service-sdk';
import DeviceServiceV2 from '@/lib/device-service-v2';

import Alert from '@/components/ui/Alert';
import BaseButton from '@/components/rbComponents/BaseButton';
import AddDeviceModal from '@/components/modals/AddDeviceModal';
import GenericTable from '@/components/GenericTable.vue';
import StatusBadge from '@/components/ui/StatusBadge';
import EditDeviceModal from '@/components/modals/EditDeviceModal';
import { debounce } from 'lodash';

export default {
  name: 'Devices',
  components: {
    Alert,
    AddDeviceModal,
    BaseButton,
    GenericTable,
    StatusBadge,
    EditDeviceModal
  },
  data() {
    return {
      columns: [
        {
          name: 'name',
          label: 'Device Name',
          sortable: true,
          classes: 'w-auto'
        },
        {
          name: 'serialNumber',
          label: 'Unique Identifier',
          sortable: true,
          classes: 'w-auto'
        },
        {
          name: 'sellerName',
          label: 'Seller Name',
          sortable: false,
          classes: 'w-auto'
        },
        {
          name: 'apkVersion',
          label: 'APK Version',
          sortable: false,
          classes: 'w-auto'
        },
        {
          name: 'environmentUrl',
          label: 'Environment',
          sortable: false,
          classes: 'w-auto'
        },
        {
          name: 'webVersion',
          label: 'Web Version',
          sortable: false,
          classes: 'w-auto'
        },
        {
          name: 'status',
          label: 'Status',
          sortable: false,
          classes: 'w-auto'
        }
      ],
      data: [],
      pagination: {
        page: 0,
        pageSize: 10,
        sortBy: 'name',
        sortDir: 'desc',
        total: 0
      },
      organizationId: null,
      errorMessage: null,
      loading: true,
      search: null,
      isRbAdmin: false
    };
  },
  async mounted() {
    const sessionUser = await getAuth().sessionUser();
    this.organizationId = sessionUser.organizationUuid;
    this.isRbAdmin = sessionUser.hasRole(Roles.RB_ADMIN);
    this.clearSearch();
    await this.getDevices();
  },
  methods: {
    clearSearch() {
      this.search = null;
    },
    async getDevices() {
      try {
        const params = {
          search: this.search,
          page: this.pagination.page,
          pageSize: this.pagination.pageSize,
          sortBy: this.pagination.sortBy,
          sortDir: this.pagination.sortDir
        };

        if (this.organizationId) {
          params.organizationId = this.organizationId;
        }

        const result = await DeviceServiceV2.listDevices(params);

        this.data = result.data;
        this.pagination = result.pagination;
      } catch (error) {
        this.errorMessage = this.parseError(error);
      } finally {
        this.loading = false;
      }
    },
    syncDevices() {
      try {
        DeviceServiceV2.syncDevices();
        this.$router.go();
      } catch (error) {
        this.errorMessage = 'Failed to sync devices';
      }
    },
    async deviceUpdated() {
      await this.getDevices();
    },
    async sort(sortBy, sortDir) {
      this.pagination.sortBy = sortBy;
      this.pagination.sortDir = sortDir;
      await this.getDevices();
    },
    async updateCurrentPage(page) {
      this.pagination.page = page;
      await this.getDevices();
    },
    debounceInput: debounce(async function () {
      this.updateCurrentPage(0);
      await this.getDevices();
    }, 500)
  }
};
</script>

<style scoped>
.container {
  text-align: left;
  margin-left: 115px;
  max-width: 1500px;
}
.search-wrap {
  padding-bottom: 1rem;
}

/* clears the ‘X’ from Internet Explorer */
input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
/* clears the ‘X’ from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}
</style>
